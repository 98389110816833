import React from 'react'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { BreakpointToMedium } from 'goustouicomponents/dist/design-language/breakpoints.module.css'
import HeroImgDesktop from '../../assets/hero_img_desktop.jpg'
import HeroImgMobile from '../../assets/hero_img_mobile.jpg'
import GoustoLogo from '../../assets/logo.png'
import BoldtFlavoursLogo from '../../assets/boldt_flavours_logo.jpg'
import { NarrowContent } from '../../layouts/NarrowContent'
import arrowDown from '../../assets/arrow-down.svg'
import css from './HeroMessage.module.css'

const HeroMessage = () => (
  <section className={css.wrapper}>
    <LayoutPageWrapper padding="large-screen-only">
      <NarrowContent>
        <div className={css.heroImage}>
          <img src={GoustoLogo} alt="Gousto logo" className={css.goustoLogo} />
          <picture>
            <source srcSet={HeroImgMobile} media={BreakpointToMedium} />
            <img
              width="100%"
              height="auto"
              src={HeroImgDesktop}
              alt="Timo Boldt cooking gousto meal"
              className={css.mainImage}
              title="Boldt Flavours, By Timo Boldt"
            />
          </picture>
          <img src={BoldtFlavoursLogo} alt="Podcast logo" className={css.podcastLogo} />
          <div className={css.arrowDownWrapper}>
            <img src={arrowDown} alt="Arrow down" className={css.arrowDown} />
          </div>
        </div>
      </NarrowContent>
    </LayoutPageWrapper>
  </section>
)

export { HeroMessage }
