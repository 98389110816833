import React from 'react'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { Heading } from 'goustouicomponents/dist/Heading'
import { NarrowContent } from '../../layouts/NarrowContent'

import css from './PrivacyStatement.module.css'

const PrivacyStatementHeader = () => (
  <section className={css.headerContainer}>
    <LayoutPageWrapper>
      <NarrowContent>
        <div className={css.header}>
          <Heading size="fontStyleXL">Privacy Statement</Heading>
        </div>
      </NarrowContent>
    </LayoutPageWrapper>
  </section>
)

export { PrivacyStatementHeader }
