import React, { useContext } from 'react'
import classnames from 'classnames'
import { useParams } from 'react-router-dom'
import { Heading } from 'goustouicomponents/dist/Heading'
import { Alert } from 'goustouicomponents/dist/Alert'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { store } from '../../store'
import { ContentLoader } from '../../components/ContentLoader'
import { RelatedContent } from '../../components/RelatedContent'
import css from './Episode.module.css'

const Episode = () => {
  const { episodeNumber, seasonNumber } = useParams()
  const { state: { episodes: { isLoading, list, hasError } } } = useContext(store)

  if (isLoading) {
    return <ContentLoader />
  }

  if (!hasError && list.length && list[seasonNumber - 1] && list[seasonNumber - 1][episodeNumber - 1]) {
    const episodeToDisplay = list[seasonNumber - 1][episodeNumber - 1]

    const { id, title, publishedAt, description, image, humanReadableLength } = episodeToDisplay

    return (
      <article>
        <section className={css.episodeHero}>
          <LayoutPageWrapper>
            <div className={css.episodeHeroContent}>
              <div className={css.imageWrapper}>
                <img src={image} alt={title} title={title} className={css.image} />
              </div>
              <Heading size="fontStyle2XL" type="h1">{title}</Heading>
              <span className={css.dateAndDuration}>
                {`${publishedAt} / ${humanReadableLength}`}
              </span>
              <div className={classnames(css.iframeWrapper, css.narrowContent)}>
                <iframe
                  className={css.iframe}
                  title="podcast"
                  width="100%"
                  height="170px"
                  frameBorder="no"
                  scrolling="no"
                  seamless
                  src={`https://player.captivate.fm/episode/${id}`}
                />
              </div>
            </div>
          </LayoutPageWrapper>
        </section>
        <section className={css.episodeDetails}>
          <LayoutPageWrapper>
            <div className={css.narrowContent}>
              <Heading size="fontStyleL" type="h2">About the episode</Heading>
              {description}
            </div>
          </LayoutPageWrapper>
        </section>
        <RelatedContent episodeNumber={episodeNumber} seasonNumber={seasonNumber} />
      </article>
    )
  }

  return (
    <LayoutPageWrapper>
      <Alert type="danger">
        <Heading size="fontStyleM">Something went wrong!</Heading>
        <p>
          If this problem persists, please get in touch with us at
          {' '}
          <a href="mailto:support@gousto.co.uk">support@gousto.co.uk</a>
        </p>
      </Alert>
    </LayoutPageWrapper>
  )
}

export { Episode }
