import ReactGA from 'react-ga'

const pageView = (page) => {
  ReactGA.pageview(page)
}

const clickGoustoFooterLink = () => {
  ReactGA.event({
    category: 'Click',
    action: 'Link to Gousto - Footer',
  })
}

const clickListenOn = (externalPlayer) => {
  ReactGA.event({
    category: 'Click',
    action: 'Listen-On Link',
    label: externalPlayer,
  })
}

const clickShareButton = (socialMedia) => {
  ReactGA.event({
    category: 'Click',
    action: 'Share-On Button',
    label: socialMedia,
  })
}

const clickTimoLinkedin = () => {
  ReactGA.event({
    category: 'Click',
    action: 'Follow me on Linkedin',
  })
}

export {
  pageView,
  clickGoustoFooterLink,
  clickListenOn,
  clickShareButton,
  clickTimoLinkedin,
}
