import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { StateProvider } from './store'
import { App } from './App'
import './index.module.css'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-32127122-8')
  TagManager.initialize({ gtmId: 'GTM-5G94V44' })
}

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <App />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
