import React from 'react'
import { AboutPodcast } from '../AboutPodcast'
import { HeroMessage } from '../HeroMessage'
import { EpisodesContainer } from '../EpisodesContainer'

const MainPage = () => (
  <div>
    <HeroMessage />
    <AboutPodcast />
    <EpisodesContainer />
  </div>
)

export { MainPage }
