import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Heading } from 'goustouicomponents/dist/Heading'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { InfoTip } from 'goustouicomponents/dist/InfoTip'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import { faFacebookF, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { NarrowContent } from '../../layouts/NarrowContent'
import { clickListenOn, clickShareButton } from '../../utils/analytics'
import Spotify from '../../assets/spotify.svg'
import Apple from '../../assets/apple.svg'
import Google from '../../assets/google.svg'
import Amazon from '../../assets/amazon.svg'
import Deezer from '../../assets/deezer.svg'
import css from './AboutPodcast.module.css'

const ListItem = ({ href, src, player }) => (
  <li>
    <a onClick={() => { clickListenOn(player) }} target="_blank" rel="noopener noreferrer" href={href} className={css.shareLink}>
      <img width="100%" height="auto" className={css.playerLogo} src={src} alt={`${player}-logo`} title={player} />
    </a>
  </li>
)

const AboutPodcast = () => {
  const [isCopied, setIsCopied] = useState(false)

  const onClickCopyLink = () => {
    setIsCopied(true)
    navigator.clipboard.writeText('https://boldtflavours.com')
    clickShareButton('copy link')
    setTimeout(() => setIsCopied(false), 2000)
  }

  return (
    <section className={css.wrapper}>
      <LayoutPageWrapper padding="large-screens-only">
        <NarrowContent>
          <div className={css.textWrapper}>
            <Heading size="fontStyleXL" type="h2">
              About the podcast
            </Heading>
            <p className={css.description}>
              Join Timo Boldt, Founder and CEO of leading unicorn recipe box company Gousto, as he talks to top company founders, CEOs and business leaders about their journey so far, what makes them tick, and how they achieve their ambitions.
              Guests include the &lsquo;nation’s PE teacher&rsquo; Joe Wicks,
              Peanut founder Michelle Kennedy and Bobby Healy, founder and CEO of Manna Drones.
            </p>
            <div className={css.linkContainer}>
              <div>
                <h4 className={css.linkTitle}>
                  Listen On
                </h4>
                <ul className={css.linkList}>
                  <ListItem href="https://open.spotify.com/show/2CoHaO82KVfQB0y2TJ1sAo?si=Mc8rz7wdQEq4qFTXdSFPKg" src={Spotify} player="spotify" />
                  <ListItem href="https://podcasts.apple.com/gb/podcast/boldt-flavours/id1536113646" src={Apple} player="apple" />
                  <ListItem href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5jYXB0aXZhdGUuZm0vYm9sZHQtZmxhdm91cnMv" src={Google} player="google" />
                  <ListItem href="https://music.amazon.co.uk/podcasts/cb5617e7-1ee9-4301-9020-4b2841f2f82a/Boldt-Flavours" src={Amazon} player="amazon" />
                  <ListItem href="https://www.deezer.com/en/show/2535532" src={Deezer} player="deezer" />
                </ul>
              </div>
              <div>
                <h4 className={css.linkTitle}>
                  Share
                </h4>
                <ul className={css.linkList}>
                  <li>
                    <div className={css.copyLinkWrapper}>
                      <button
                        className={classnames(css.copylink, css.shareLink)}
                        type="button"
                        onClick={onClickCopyLink}
                        aria-label="copy link button"
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </button>
                      <div className={css.infoTipWrapper}>
                        {isCopied && <InfoTip minWidth="110px">Link copied!</InfoTip>}
                      </div>
                    </div>
                  </li>
                  <li>
                    <FacebookShareButton
                      url="https://boldtflavours.com"
                      quote="The stories behind the UK’s most inspiring leaders in retail and tech, with Gousto’s Timo Boldt."
                      className={css.shareLink}
                      onClick={() => {
                        clickShareButton('facebook')
                      }}
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <TwitterShareButton
                      url="https://www.boldtflavours.com"
                      title="The stories behind the UK’s most inspiring leaders in retail and tech, with Gousto’s Timo Boldt."
                      hashtags={['boldtflavours']}
                      className={css.shareLink}
                      onClick={() => {
                        clickShareButton('twitter')
                      }}
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url="https://www.boldtflavours.com"
                      summary="The stories behind the UK’s most inspiring leaders in retail and tech, with Gousto’s Timo Boldt."
                      title="Boldt Flavours"
                      className={css.shareLink}
                      onClick={() => {
                        clickShareButton('linkedin')
                      }}
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </LinkedinShareButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </NarrowContent>
      </LayoutPageWrapper>
    </section>
  )
}

ListItem.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  player: PropTypes.string.isRequired,
}

export { AboutPodcast }
