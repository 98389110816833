const stripPrefix = (str) => {
  const capitaliseWord = (word) => word.charAt(0).toUpperCase() + word.slice(1)

  const capitalise = str.split(':').map((item, index) => {
    if (index === 0) {
      return item
    }

    return capitaliseWord(item)
  }).join('')

  return capitalise
}

export { stripPrefix }
