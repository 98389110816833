import React from 'react'
import { CTA } from 'goustouicomponents/dist/CTA'
import { Heading } from 'goustouicomponents/dist/Heading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import css from './Promo.module.css'
import { clickTimoLinkedin } from '../../utils/analytics'

const onClick = () => {
  clickTimoLinkedin()
  window.open('https://www.linkedin.com/in/timo-boldt/')
}

const Promo = () => (
  <section className={css.wrapper}>
    <div className={css.content}>
      <Heading size="fontStyleXL" type="h2">New episodes every Wednesday!</Heading>
      <p>Stay updated on future guests and upcoming conversations via LinkedIn.</p>
      <CTA onClick={onClick} isFullWidth="small-screens-only">
        <FontAwesomeIcon icon={faHeart} className={css.icon} />
        Follow Timo
      </CTA>
    </div>
  </section>
)

export { Promo }
