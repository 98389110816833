import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'goustouicomponents/dist/Alert'
import { CTA } from 'goustouicomponents/dist/CTA'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { Heading } from 'goustouicomponents/dist/Heading'
import { store } from '../../store'
import { ContentLoader } from '../ContentLoader'
import { EpisodePromotion } from '../EpisodePromotion'
import css from './RelatedContent.module.css'

const onClick = () => {
  window.location.href = '/'
}

const extractEpisodeData = ({ episodeNumber, seasonNumber, list, label }) => {
  const { image, publishedAt, title } = list[seasonNumber - 1][episodeNumber - 1]

  return {
    title,
    imageSrc: image,
    date: publishedAt,
    label,
    url: `/season/${seasonNumber}/episode/${episodeNumber}`,
  }
}

const getRightEpisode = ({ episodeNumber, seasonNumber, list }) => {
  let rightEpisodeNumber = episodeNumber + 1
  let rightSeasonNumber = seasonNumber
  let label = 'Next episode'

  if (list[rightSeasonNumber - 1].length < rightEpisodeNumber) {
    // if latest episode in season

    if (list.length > rightSeasonNumber) {
      // if there is a season after current, show first episode of that one
      rightSeasonNumber = seasonNumber + 1
      rightEpisodeNumber = 1
    } else if (list[rightSeasonNumber - 1].length < 3) {
      // if less than three episodes in the current season, we need to go to the previous season, latest episode
      rightSeasonNumber = seasonNumber - 1
      rightEpisodeNumber = list[rightSeasonNumber - 1].length
      label = 'Previous episode'
    } else {
      // if more than three episodes, show two episodes before this one, as the left one will show the previous one
      rightEpisodeNumber = episodeNumber - 2
      label = 'Previous episode'
    }
  }

  return extractEpisodeData({ episodeNumber: rightEpisodeNumber, seasonNumber: rightSeasonNumber, list, label })
}

const getLeftEpisode = ({ episodeNumber, seasonNumber, list }) => {
  let leftEpisodeNumber = episodeNumber - 1
  let leftSeasonNumber = leftEpisodeNumber <= 0 ? seasonNumber - 1 : seasonNumber
  let label = 'Previous episode'

  if (leftSeasonNumber <= 0) {
    // if first season, wrap around and show latest episode
    leftSeasonNumber = list.length
    label = 'Latest episode'
  }

  if (leftEpisodeNumber <= 0) {
    // if not the first season, show the last episode of the previous season
    leftEpisodeNumber = list[leftSeasonNumber - 1].length
  }

  return extractEpisodeData({ episodeNumber: leftEpisodeNumber, seasonNumber: leftSeasonNumber, list, label })
}

const getRelevantEpisodes = ({ episodeNumber, seasonNumber, list }) => {
  const currentEpisodeNumber = Number.parseInt(episodeNumber, 10)
  const currentSeasonNumber = Number.parseInt(seasonNumber, 10)

  return [
    getLeftEpisode({ episodeNumber: currentEpisodeNumber, seasonNumber: currentSeasonNumber, list }),
    getRightEpisode({ episodeNumber: currentEpisodeNumber, seasonNumber: currentSeasonNumber, list }),
  ]
}

const RelatedContent = ({ episodeNumber, seasonNumber }) => {
  const { state: { episodes: { isLoading, list, hasError } } } = useContext(store)

  if (isLoading) {
    return <ContentLoader />
  }

  if (!hasError && list.length) {
    const relevantEpisodes = getRelevantEpisodes({ episodeNumber, seasonNumber, list })

    return (
      <section className={css.wrapper}>
        <LayoutPageWrapper>
          <div className={css.headingWrapper}>
            <Heading isCenter type="h2" size="fontStyleXL">You might also enjoy</Heading>
          </div>
          <div className={css.episodesWrapper}>
            {
              relevantEpisodes.map(({ date, imageSrc, label, title, url }) => (
                <div className={css.episodeWrapper} key={url}>
                  <EpisodePromotion
                    date={date}
                    imageSrc={imageSrc}
                    label={label}
                    title={title}
                    url={url}
                  />
                </div>
              ))
            }
          </div>
          <div className={css.ctaWrapper}>
            <CTA onClick={onClick} isFullWidth="small-screens-only" size="small">
              View all episodes
            </CTA>
          </div>
        </LayoutPageWrapper>
      </section>
    )
  }

  return (
    <section className={css.wrapper}>
      <LayoutPageWrapper>
        <Alert type="danger">
          <Heading size="fontStyleM">Something went wrong!</Heading>
          <p>
            If this problem persists, please get in touch with us at
            {' '}
            <a href="mailto:support@gousto.co.uk">support@gousto.co.uk</a>
          </p>
        </Alert>
      </LayoutPageWrapper>
    </section>
  )
}

RelatedContent.propTypes = {
  episodeNumber: PropTypes.string.isRequired,
  seasonNumber: PropTypes.string.isRequired,
}

export { RelatedContent }
