import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { SET_EPISODES } from './actionTypes'
import { getFormattedDuration } from '../utils'

const initialState = {
  episodes: {
    isLoading: false,
    hasError: false,
    list: [],
  },
}

const reduceEpisodes = (episodes) => {
  const reducedEpisodes = []
  episodes.forEach((episode) => {
    const {
      guid,
      pubDate,
      contentEncoded,
      title,
      itunesImage,
      itunesDuration,
      itunesEpisode,
      itunesSeason,
    } = episode

    const newDate = new Date(pubDate)
    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    const publishedAt = new Intl.DateTimeFormat('en-US', options).format(newDate)

    const seasonNumber = Number.parseInt(itunesSeason, 10)
    const episodeNumber = Number.parseInt(itunesEpisode, 10)

    if (!seasonNumber || !episodeNumber) {
      return
    }

    if (!reducedEpisodes[seasonNumber - 1]) {
      reducedEpisodes[seasonNumber - 1] = []
    }

    reducedEpisodes[seasonNumber - 1][episodeNumber - 1] = {
      id: guid?._,
      title,
      publishedAt,
      description: parse(contentEncoded),
      image: itunesImage?.href,
      humanReadableLength: getFormattedDuration(itunesDuration),
      episodeNumber,
      seasonNumber,
    }
  })

  return reducedEpisodes
}

const store = createContext(initialState)
const { Provider } = store

const StateProvider = ({ children, overrideDispatch }) => {
  const [state, originalDispatch] = useReducer((currentState, action) => {
    switch (action.type) {
      case SET_EPISODES: {
        const newState = {
          ...currentState,
          episodes: {
            list: reduceEpisodes(action.payload.episodes.list),
            hasError: action.payload.episodes.hasError,
            isLoading: action.payload.episodes.isLoading,
          },
        }

        return newState
      }
      default:
        throw new Error('Invalid action type')
    }
  }, initialState)

  const dispatch = overrideDispatch || originalDispatch

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  overrideDispatch: PropTypes.func,
}

StateProvider.defaultProps = {
  overrideDispatch: null,
}

export { store, StateProvider, initialState }
