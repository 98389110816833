import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { pageView } from '../../utils'
import css from './Header.module.css'

const Header = () => {
  const location = useLocation()
  const pageLocation = location.pathname

  useEffect(() => {
    pageView(pageLocation)
  }, [pageLocation])

  return (
    <header className={css.wrapper}>
      <LayoutPageWrapper>
        <Link
          className={css.logo}
          to="/"
        >
          Bold[t] Flavours
        </Link>
      </LayoutPageWrapper>
    </header>
  )
}

export { Header }
