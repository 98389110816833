const getFormattedDuration = (duration) => {
  const split = duration.split(':')

  if (split.length === 2) {
    return `${split[0]} mins, ${split[1]} secs`
  }

  if (split.length === 3) {
    return `${split[0]} hrs, ${split[1]} mins, ${split[2]} secs`
  }

  return duration
}

export { getFormattedDuration }
