import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import css from './SectionWrapper.module.css'

const SectionWrapper = ({ children, hasPadding, isCentered }) => (
  <section
    className={
      classnames(
        css.sectionWrapper,
        {
          [css.isCentered]: isCentered,
          [css.hasPadding]: hasPadding,
        },
      )
    }
  >
    {children}
  </section>
)

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hasPadding: PropTypes.bool,
  isCentered: PropTypes.bool,
}

SectionWrapper.defaultProps = {
  hasPadding: false,
  isCentered: false,
}

export { SectionWrapper }
