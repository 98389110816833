import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CTA } from 'goustouicomponents/dist/CTA'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { SectionWrapper } from '../../SectionWrapper'
import css from './EpisodeTile.module.css'

const EpisodeTile = ({ episode }) => {
  const {
    title,
    image,
    episodeNumber,
    seasonNumber,
    publishedAt,
    humanReadableLength,
  } = episode

  return (
    <SectionWrapper>
      <Link to={`/season/${seasonNumber}/episode/${episodeNumber}`}>
        <div className={css.episodeContainer}>
          <div className={css.episodeInfoContainer}>
            {episode && (
            <img
              className={css.episodeImage}
              src={image}
              alt={title}
              title={title}
              loading="lazy"
              width="100%"
              height="auto"
            />
            )}
            <div className={css.episodeInfo}>
              <h2 className={css.episodeHeading}>{title}</h2>
              <p className={css.dateAndDuration}>
                {publishedAt}
              </p>
              <p className={css.dateAndDuration}>
                {humanReadableLength}
              </p>
            </div>
          </div>
          <CTA isFullWidth>
            <FontAwesomeIcon icon={faPlayCircle} className={css.playIcon} />
            Play episode
          </CTA>
        </div>
      </Link>
    </SectionWrapper>
  )
}

EpisodeTile.defaultProps = {
  episode: {},
}

EpisodeTile.propTypes = {
  episode: PropTypes.shape({
    publishedAt: PropTypes.string,
    description: PropTypes.node,
    humanReadableLength: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    episodeNumber: PropTypes.string,
    seasonNumber: PropTypes.string,
  }),
}

export { EpisodeTile }
