import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import css from './EpisodePromotion.module.css'

const EpisodePromotion = ({ date, imageSrc, label, title, url }) => (
  <Link className={css.wrapper} to={url}>
    <span className={css.imageWrapper}>
      <img className={css.image} src={imageSrc} alt={title} title={title} loading="lazy" />
    </span>
    <span className={css.content}>
      {Boolean(label) && <span className={css.label}>{label}</span>}
      <span className={css.title}>{title}</span>
      {Boolean(date) && <span className={css.releaseDate}>{date}</span>}
      <FontAwesomeIcon icon={faPlayCircle} className={css.playIcon} />
    </span>
  </Link>
)

EpisodePromotion.propTypes = {
  date: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

EpisodePromotion.defaultProps = {
  date: null,
  label: null,
}

export { EpisodePromotion }
