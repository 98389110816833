import { useEffect, useState } from 'react'

const useFetch = ({ url, headers, options, parameters }) => {
  const [fetchResponse, setFetchResponse] = useState()
  const [isFetchLoading, setFetchLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false)

  useEffect(() => {
    if (url) {
      const abortController = new global.AbortController()
      const { signal } = abortController

      const urlToFetch = new URL(url)

      if (parameters) {
        urlToFetch.search = new URLSearchParams(parameters)
      }

      const fetchData = async () => {
        try {
          setFetchLoading(true)

          const res = await global.fetch(urlToFetch.toString(), {
            ...options,
            headers: {
              ...headers,
            },
          })

          const text = await res.text()

          if (!signal.aborted) {
            setFetchResponse(text)
          }
        } catch (error) {
          if (!signal.aborted) {
            setFetchError(error)
          }
        } finally {
          if (!signal.aborted) {
            setFetchLoading(false)
          }
        }
      }

      fetchData()
    }
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return { isFetchLoading, fetchResponse, fetchError }
}

export { useFetch }
