import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Alert } from 'goustouicomponents/dist/Alert'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import { Heading } from 'goustouicomponents/dist/Heading'
import { Grid } from 'goustouicomponents/dist/Grid'
import { Column } from 'goustouicomponents/dist/Column'
import { EpisodeTile } from './EpisodeTile'
import { store } from '../../store'
import { NarrowContent } from '../../layouts/NarrowContent'
import { ContentLoader } from '../ContentLoader'
import { Promo } from '../Promo'
import { reverseNestedArray } from '../../utils'
import css from './EpisodesContainer.module.css'

const SeasonList = ({ list, newestFirst }) => (list.map((season, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <section key={index}>
    <h3 className={css.seasonHeader}>
      <span className={css.seasonHeaderText}>
        Season&nbsp;
        {newestFirst ? list.length - index : index + 1}
      </span>
    </h3>
    <Grid>
      {season.map((episode) => (
        <Column
          smallScreen={12}
          mediumScreen={4}
          largeScreen={3}
          hasPaddingSmallScreen={false}
          hasPaddingMediumScreen={false}
          hasPaddingLargeScreen={false}
          key={episode.id}
        >
          <EpisodeTile key={episode?.id} episode={episode} />
        </Column>
      ))}
    </Grid>
    { index === 0 && <Promo /> }
  </section>
)))

const EpisodesContainerContent = ({ isLoading, isNewestFirst, list, hasError }) => {
  if (!hasError && isLoading) {
    return <ContentLoader />
  }

  if (!hasError && list.length) {
    const orderedList = isNewestFirst ? reverseNestedArray(list) : list

    return <SeasonList list={orderedList} newestFirst={isNewestFirst} />
  }

  return (
    <Alert type="danger">
      <Heading size="fontStyleM">Something went wrong!</Heading>
      <p>
        If this problem persists, please get in touch with us at
        {' '}
        <a href="mailto:support@gousto.co.uk">support@gousto.co.uk</a>
      </p>
    </Alert>
  )
}

EpisodesContainerContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isNewestFirst: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())).isRequired,
}

const EpisodesContainer = () => {
  const { state: { episodes } } = useContext(store)
  const [isNewestFirst, setIsNewestFirst] = useState(true)

  return (
    <section className={css.wrapper}>
      <LayoutPageWrapper>
        <NarrowContent>
          <div className={css.heading}>
            <Heading size="fontStyleXL" type="h2">Episodes</Heading>
            <button
              type="button"
              className={
                classnames(
                  {
                    [css.isActive]: isNewestFirst,
                  },
                  css.sorting,
                )
              }
              onClick={() => { setIsNewestFirst(!isNewestFirst) }}
            >
              <FontAwesomeIcon className={css.sortingArrow} icon={faChevronUp} />
              {isNewestFirst ? 'Newest' : 'Oldest'}
            </button>
          </div>
          <EpisodesContainerContent
            isLoading={episodes.isLoading}
            isNewestFirst={isNewestFirst}
            hasError={episodes.hasError}
            list={episodes.list}
          />
        </NarrowContent>
      </LayoutPageWrapper>
    </section>
  )
}

export { EpisodesContainer }
