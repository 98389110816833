const reverseNestedArray = (list) => {
  if (!Array.isArray(list)) {
    return list
  }

  const newList = []
  const listLength = list.length

  list.forEach((item, index) => {
    const newItem = list[listLength - 1 - index]

    newList.push(Array.isArray(newItem) ? reverseNestedArray(newItem) : newItem)
  })

  return newList
}

export { reverseNestedArray }
