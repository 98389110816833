import React, { useContext, useEffect } from 'react'
import { parseString } from 'xml2js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { CookieBanner } from 'goustouicomponents/dist/CookieBanner'
import { ScrollToTop } from './utils/ScrollToTop'
import { Episode } from './routes/Episode'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { MainPage } from './components/MainPage'
import { PrivacyStatement } from './routes/PrivacyStatement'
import { useFetch } from './hooks/useFetch'
import { store } from './store'
import { setEpisodes } from './actions/episodes'
import { stripPrefix } from './utils'

const App = () => {
  const { dispatch } = useContext(store)

  const { isFetchLoading, fetchError, fetchResponse } = useFetch({
    url: 'https://feeds.captivate.fm/boldt-flavours/',
    needsAuthorization: false,
  })

  useEffect(() => {
    if (isFetchLoading) {
      dispatch(setEpisodes({
        episodes: {
          list: [],
          hasError: false,
          isLoading: true,
        },
      }))
    } else if (!fetchError && fetchResponse) {
      parseString(
        fetchResponse,
        {
          tagNameProcessors: [stripPrefix],
          explicitArray: false,
          mergeAttrs: true,
        },
        (err, result) => {
          const episodes = result?.rss?.channel?.item

          if (!err && episodes) {
            return dispatch(setEpisodes({
              episodes: {
                list: Array.isArray(episodes) ? episodes : [episodes],
                hasError: false,
                isLoading: false,
              },
            }))
          }

          return (
            dispatch(setEpisodes({
              episodes: {
                list: [],
                hasError: true,
                isLoading: false,
              },
            }))
          )
        },
      )
    } else {
      dispatch(setEpisodes({
        episodes: {
          list: [],
          hasError: true,
          isLoading: false,
        },
      }))
    }
  }, [isFetchLoading, fetchError, fetchResponse, dispatch])

  return (
    <Router>
      <CookieBanner />
      <Header />
      <ScrollToTop />
      <Switch>
        <Route path="/privacy-statement" component={PrivacyStatement} />
        <Route
          path="/season/:seasonNumber/episode/:episodeNumber"
          component={Episode}
        />
        <Route path="*" component={MainPage} />
      </Switch>
      <Footer />
    </Router>
  )
}

export { App }
