import React from 'react'
import PropTypes from 'prop-types'
import css from './NarrowContent.module.css'

const NarrowContent = ({ children }) => <section className={css.wrapper}>{children}</section>

NarrowContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export { NarrowContent }
