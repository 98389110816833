import React from 'react'
import { PrivacyStatementHeader } from './PrivacyStatementHeader'
import { PrivacyStatementContent } from './PrivacyStatementContent'

import css from './PrivacyStatement.module.css'

const PrivacyStatement = () => (
  <section className={css.wrapper}>
    <PrivacyStatementHeader />
    <PrivacyStatementContent />
  </section>
)

export { PrivacyStatement }
