import React from 'react'
import { Link } from 'react-router-dom'
import { LayoutPageWrapper } from 'goustouicomponents/dist/LayoutPageWrapper'
import PoweredByGousto from '../../assets/Powered-by-Gousto.svg'
import { clickGoustoFooterLink } from '../../utils/analytics'
import css from './Footer.module.css'

const Footer = () => (
  <footer className={css.wrapper}>
    <LayoutPageWrapper>
      <div className={css.container}>
        <a
          className={css.logo}
          href="https://www.gousto.co.uk"
          rel="noopener noreferrer"
          target="_blank"
          onClick={clickGoustoFooterLink}
        >
          <img src={PoweredByGousto} alt="gousto link" title="Powered by Gousto" loading="lazy" />
        </a>
        <nav>
          <ul className={css.linksWrapper}>
            <li>
              <h4 className={css.linkTitle}>Try gousto today at</h4>
              <a
                href="https://www.gousto.co.uk"
                rel="noopener noreferrer"
                target="_blank"
                onClick={clickGoustoFooterLink}
              >
                www.gousto.co.uk
              </a>
            </li>
            <li>
              <h4 className={css.linkTitle}>View privacy policy</h4>
              <Link to="/privacy-statement">
                Privacy policy
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </LayoutPageWrapper>
  </footer>
)

export { Footer }
